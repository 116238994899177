import axios from '@/plugins/axios'

export default {

  async listar (dados) {
    const parametros = new URLSearchParams(dados).toString()
    return await axios.get(`empresas?${parametros}`)
  },

  async buscar (id) {
    return await axios.get(`empresas/${id}`)
  },

  async inserir (dados) {
    return await axios.post('empresas', dados)
  },

  async atualizar (id, dados) {
    return await axios.post(`empresas/${id}`, dados)
  },

  async deletar (id) {
    return await axios.delete(`empresas/${id}`)
  },

}
