<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <btn-adicionar
          v-if="$temPermissao('empresa-insercao')"
          to="/empresas/adicionar"
        />
      </v-col>
    </v-row>

    <v-row
      v-if="$temPermissao('empresa-listagem')"
      class="mt-4"
    >
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :loading="carregando"
          :headers="cabecalho"
          :items="empresas"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50]
          }"
          @update:options="carregarEmpresas"
        >
          <template
            v-slot:[`item.actions`]="{ item }"
            class="text-right"
          >
            <table-btn-editar
              v-if="$temPermissao('empresa-visualizacao')"
              :href="`/empresas/${item.id}/editar`"
            />
            <table-btn-deletar @click="deletarEmpresa(item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <dialog-deletar
      :mostrar="dialogDeletar"
      :carregando="carregandoDeletar"
      @confirmar="deletar"
      @fechar="dialogDeletar = false"
    />
  </v-container>
</template>

<script>
  import empresas from '@/api/empresas'

  export default {
    components: {
      DialogDeletar: () => import('@/components/dialog/DialogDeletar'),
    },

    data () {
      return {
        carregando: false,
        carregandoDeletar: false,
        dialogDeletar: false,
        totalRegistros: 0,
        idExcluir: null,
        cabecalho: [
          { text: '#', value: 'id' },
          { text: 'Descrição', value: 'descricao' },
          { text: 'Ações', value: 'actions', align: 'right' },
        ],
        empresas: [],
        paginacao: {},
      }
    },

    methods: {
      async carregarEmpresas () {
        try {
          this.carregando = true

          const resposta = await empresas.listar({
            pagina: this.paginacao.page,
            por_pagina: this.paginacao.itemsPerPage,
          })

          this.empresas = resposta.data.data
          this.totalRegistros = resposta.data.meta.total
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregando = false
        }
      },

      async deletar () {
        try {
          this.carregandoDeletar = true

          await empresas.deletar(this.idExcluir)

          this.carregarEmpresas()

          this.$snackbar.mostrar({ cor: 'success', mensagem: this.$mensagens._('sucesso_deletar') })
        } catch (e) {
          this.$snackbar.mostrar({ cor: 'error', mensagem: this.$erroApi._(e) })
        } finally {
          this.carregandoDeletar = false
          this.dialogDeletar = false
        }
      },

      deletarEmpresa (empresa) {
        this.idExcluir = empresa.id
        this.dialogDeletar = true
      },

    },

  }
</script>
